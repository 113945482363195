export default function heroVideo() {
    const heroVideos = document.querySelectorAll('.js-hero-video');

    heroVideos.forEach((videoWrapper) => {
        const {videoId} = videoWrapper.dataset;
        if (!videoId) { return; }

        const video = videoWrapper.querySelector(`#hero-video-${videoId}`);
        const togglebutton = videoWrapper.querySelector(`#hero-video-button-${videoId}`);
        const iconPlay = togglebutton.querySelector('.js-icon-play');

        const iconPause = togglebutton.querySelector('.js-icon-pause');
        iconPause.setAttribute('hidden', 'hidden');

        if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            if (video) {
                video.play();
                togglebutton.setAttribute('aria-label', 'Video stoppen');
                iconPause.removeAttribute('hidden');
                iconPlay.setAttribute('hidden', 'hidden');
            }
        }

        togglebutton.addEventListener('click', () => {
            if (video.paused) {
                video.play();
                iconPause.removeAttribute('hidden');
                iconPlay.setAttribute('hidden', 'hidden');
                togglebutton.setAttribute('aria-label', 'Video stoppen');
            } else {
                video.pause();
                iconPlay.removeAttribute('hidden');
                iconPause.setAttribute('hidden', 'hidden');
                togglebutton.setAttribute('aria-label', 'Video starten');
            }
        });
    });
}
