export default function navigationToggle() {
    const toggleButton = document.querySelector('.js-navtoggle');
    const menu = document.querySelector('.js-menu');
    const body = document.querySelector('body');

    if (toggleButton) {
        const toggleButtonInnerText = toggleButton.querySelector('.js-togglebutton-text');

        toggleButton.addEventListener('click', () => {
            menu.classList.toggle('is-open');
            body.classList.toggle('scrolllock');

            if (toggleButton.getAttribute('aria-expanded') === 'true') {
                toggleButton.setAttribute('aria-expanded', 'false');
                toggleButtonInnerText.innerHTML = 'Menü öffnen';
            } else {
                toggleButton.setAttribute('aria-expanded', 'true');
                toggleButtonInnerText.innerHTML = 'Menü schließen';
            }
        });
    }

    const submenuToggles = document.querySelectorAll('.js-submenu-toggle');

    submenuToggles.forEach((submenuToggle) => {
        submenuToggle.addEventListener('click', (event) => {
            const parent = event.target.closest('.js-submenu-parent');
            parent.classList.toggle('has-open-submenu');
        });
    });
}
