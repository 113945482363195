export default function loadMorePosts() {
    const listsWrapper = document.querySelectorAll('.js-load-more-posts-wrapper');

    listsWrapper.forEach((wrapper) => {
        const list = wrapper.querySelector('.js-load-more-posts-list');
        const button = wrapper.querySelector('.js-load-more-posts-button');

        if (!list || !button) {
            return;
        }

        button.addEventListener('click', () => {
            const maxPages = Number(list.dataset.maxPages);
            const currentPage = Number(list.dataset.currentPage);
            const nextPage = currentPage + 1;

            if (maxPages <= currentPage) {
                return;
            }

            const fetchUrl = `/wp-json/pp-karriere/v1/blog/?page=${nextPage}`;

            button.setAttribute('disabled', 'disabled');
            button.classList.add('has-loading-animation');

            fetch(fetchUrl)
                .then((response) => response.json())
                .then((data) => {
                    list.insertAdjacentHTML('beforeend', data);

                    // nachgeladenes HTML kann lazy zu ladende Bilder enthalten
                    const newImages = document.querySelectorAll('main noscript.loading-lazy');
                    // eslint-disable-next-line no-undef
                    if (newImages && loadingAttributePolyfill) {
                        newImages.forEach((newImage) => {
                            // eslint-disable-next-line no-undef
                            loadingAttributePolyfill.prepareElement(newImage);
                        });
                    }

                    list.dataset.currentPage = nextPage;

                    if (maxPages <= nextPage) {
                        button.remove();
                    } else {
                        button.removeAttribute('disabled', 'disabled');
                        button.classList.remove('has-loading-animation');
                    }
                });
        });
    });
}
