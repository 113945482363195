export default function expandingCards() {
    const dialogWrappers = document.querySelectorAll('.js-dialog-wrapper');

    dialogWrappers.forEach((dialogWrapper) => {
        const dialog = dialogWrapper.querySelector('dialog');
        const showButton = dialogWrapper.querySelector('.js-open-button');
        const closeButton = dialogWrapper.querySelector('.js-close-button');

        showButton.addEventListener('click', () => {
            dialog.showModal();
        });

        closeButton.addEventListener('click', () => {
            dialog.close();
        });
    });
}
